<template>
  <app-main>
    <template #headerText>
      <h4>Congratulations to the winners:</h4>
      <p>C. ABBOTT, VIC<br />
K. ALLEN, VIC<br />
R. AMANATIDIS, SA<br />
F. AMANATIDIS, SA<br />
S. AMOS, QLD<br />
M. ANDERSON, NSW<br />
A. ANDREWS, WA<br />
L. ARMSTRONG, NSW<br />
K. ARTER, NSW<br />
Z. BADGERY, NSW<br />
M. BADZA, SA<br />
M. BALDOCK, SA<br />
D. BARCLAY, SA<br />
L. BARNES, VIC<br />
P. BECKHAM, WA<br />
H. BELLAMY, QLD<br />
G. BOUGHEN, TAS<br />
A. BOWATER, VIC<br />
I. BOYD, VIC<br />
E. BROOKS, QLD<br />
N. BROWN, WA<br />
J. BRUCE, VIC<br />
D. BUI, VIC<br />
D. BUTLER, VIC<br />
S. CALIGARI, ACT<br />
N. CAMARDA, NSW<br />
D. CAMPBELL, VIC<br />
T. CAMPBELL, NSW<br />
J. CAMPBELL, NSW<br />
C. CAMPBELL, NSW<br />
A. CARROLL, SA<br />
G. CARTS, SA<br />
D. CASTILLO, NSW<br />
S. CHARBEL, NSW<br />
L. CHAUDHRY, WA<br />
S. CHONG, NSW<br />
K. CHOW, NSW<br />
T. CLARKE, VIC<br />
D. CLIFFORD, VIC<br />
P. COACHERS, QLD<br />
A. COCO, QLD<br />
L. COLE, NSW<br />
T. COLOVIC, QLD<br />
M. COLOVIC, QLD<br />
S. COMPTON, NSW<br />
S. CONLAN, VIC<br />
J. CONNOLLY, VIC<br />
V. CONSTABLE, SA<br />
M. COOPER, WA<br />
E. CORNISH, VIC<br />
C. COX, SA<br />
K. CRASKE, VIC<br />
L. CROMPTON, NT<br />
J. CROWLEY, NSW<br />
B. CROWLEY, WA<br />
D. CURTIS, SA<br />
A. CUSATO, WA<br />
E. DABELSTEIN, NSW<br />
N. DAIDONE, VIC<br />
L. DAIDONE, VIC<br />
T. DANIELIAN, NSW<br />
E. DANIELL, SA<br />
M. DE IONNO, VIC<br />
C. DENNY, VIC<br />
J. DENT, TAS<br />
H. DEZIUS, QLD<br />
G. DISTEFANO, NSW<br />
W. DUNCAN, WA<br />
M. DUNCAN, NSW<br />
F. DURBIDGE, SA<br />
J. EATON, WA<br />
V. EILEEN WRIGHT, WA<br />
G. EPHRAIMS, VIC<br />
A. FAULKNER, VIC<br />
A. FENLON, ACT<br />
C. FERRONI, QLD<br />
K. FIELD, SA<br />
L. FLYNN, WA<br />
S. FORREST, SA<br />
L. FORRESTER, WA<br />
A. FOSTER, NSW<br />
A. FRY, QLD<br />
M. FULLER, NSW<br />
T. GALLWEY, VIC<br />
S. GAUNT, VIC<br />
N. GIANG, VIC<br />
B. GILKISON, NSW<br />
R. GLAVAN, NSW<br />
C. GLEESON, VIC<br />
S. GOMEZ, QLD<br />
S. GREEN, VIC<br />
T. GREENWOOD, SA<br />
C. GRENDON, VIC<br />
M. GURNEY, NSW<br />
M. HALL, NSW<br />
A. HANNAH, NSW<br />
G. HANNAN, QLD<br />
S. HARKIN, SA<br />
B. HARVEY, NSW<br />
J. HAWKINS, NSW<br />
J. HAYNES, NSW<br />
E. HEMMING, VIC<br />
J. HENNY, WA<br />
J. HENSCHKE, SA<br />
C. HIGGS, QLD<br />
K. HILDEBRAND, VIC<br />
A. HOOKWAY, SA<br />
I. HUDSON, VIC<br />
G. HUNG, QLD<br />
L. ISAAC, SA<br />
K. JAMES, NSW<br />
A. JANG, NSW<br />
C. JONES, QLD<br />
B. JOYCE, TAS<br />
D. JUHN, NSW<br />
O. K, SA<br />
A. KAMATH, NSW<br />
R. KAY, WA<br />
G. KEMP, VIC<br />
G. KILPATRICK, SA<br />
J. KIM, NSW<br />
D. KIM, NSW<br />
C. KING, QLD<br />
A. KNEEBONE, QLD<br />
D. KYRI, VIC<br />
A. L, NSW<br />
C. LAMPARD, VIC<br />
SORT<br />
J. LAWRENCE, NSW<br />
F. LEBSANFT, QLD<br />
L. LEE, NSW<br />
J. LESKIEWICZ, WA<br />
Y. LI, VIC<br />
S. LIN, NSW<br />
F. LIN, VIC<br />
L. LIN, QLD<br />
K. LODGE, SA<br />
N. LUSCOMBE, NSW<br />
C. MACAULAY, NSW<br />
G. MANLEY, VIC<br />
L. MARSHALL, VIC<br />
S. MARTIN, WA<br />
T. MASLEN, NSW<br />
R. MATTINSON, QLD<br />
T. MCCAFFERTY, QLD<br />
R. MCCARTHY, VIC<br />
L. MCCOY, VIC<br />
C. MCDONALD, SA<br />
G. MCDONNELL, VIC<br />
K. MCGUIRK, WA<br />
D. MCMAHON, WA<br />
A. ME, VIC<br />
S. MERHI, VIC<br />
G. METHORST, TAS<br />
G. MILLS, NSW<br />
P. MITCHELL, QLD<br />
M. MORAN, VIC<br />
L. MORELLO, NSW<br />
R. MOUK, WA<br />
B. MOUK, WA<br />
A. MUELLER, TAS<br />
T. MUELLER, QLD<br />
C. MULHOLLAND, NSW<br />
A. MURPHY, QLD<br />
M. NAZZARI, VIC<br />
C. NEAL, VIC<br />
C. NEALE, VIC<br />
E. NEALE, VIC<br />
M. NEW, QLD<br />
D. NICHOLLS, WA<br />
W. NICOLIAS, WA<br />
F. NIKOLIC, QLD<br />
C. NOGAJ, SA<br />
N. NOR, SA<br />
T. NUCIFORA, QLD<br />
G. NUCIFORA, QLD<br />
A. O'SHAUGHNESSY, NSW<br />
C. O’BRIEN, QLD<br />
F. OZDEMIR, VIC<br />
L. PAONN, WA<br />
E. PAPARELLA, SA<br />
T. PARKER, QLD<br />
A. PAYARES GALVIS, WA<br />
K. PEAKE, WA<br />
T. PHAM, QLD<br />
A. PHAM, QLD<br />
E. PHILLIPS, NSW<br />
A. PHILLIPS, NSW<br />
B. PHILLIPS, SA<br />
J. PIERS, VIC<br />
L. POTTINGER, SA<br />
A. PROUT, QLD<br />
J. RANKINE, SA<br />
M. RANKINE, SA<br />
V. RASAGAM, NSW<br />
B. REINMUTH, VIC<br />
F. REYNOLDS, QLD<br />
F. RICHARDS, VIC<br />
S. RIDDLE, SA<br />
J. RIDDLE, SA<br />
K. ROBERTS, NT<br />
H. ROBINSON, VIC<br />
S. ROSS, QLD<br />
M. ROSS, VIC<br />
R. ROWBERRY, SA<br />
J. RUFUS, NSW<br />
T. RUSSELL, QLD<br />
K. RYAN, VIC<br />
G. SCHERIANI, VIC<br />
M. SCHWARZ, NSW<br />
S. SELLERS, NSW<br />
P. SIMPSON, VIC<br />
D. SISALIO, QLD<br />
N. SKINNER, NSW<br />
T. SMITH, VIC<br />
J. SMITH, QLD<br />
D. SMITH, NSW<br />
A. SMITH, NSW<br />
M. SORAK, SA<br />
S. SORAK, SA<br />
P. SORGIOVANNI, WA<br />
B. SOU, NSW<br />
D. SPANN, QLD<br />
M. SPAUL, NSW<br />
B. STEVENS, QLD<br />
B. STEVENS, QLD<br />
P. STEWART, VIC<br />
J. STONER, SA<br />
E. SZIMA, QLD<br />
P. SZULC, VIC<br />
C. TANZER, QLD<br />
M. TAYLOR, VIC<br />
S. TAYLOR, VIC<br />
J. THOMPSON, SA<br />
R. THYER, WA<br />
H. TOMLINSON, QLD<br />
A. VALLARIO, NSW<br />
K. VANDERMEYDE, QLD<br />
A. VERMA, NSW<br />
N. VICK, VIC<br />
K. VIKA, VIC<br />
T. VOSS, NSW<br />
R. VOSS, NSW<br />
G. WALKER, QLD<br />
S. WALKER, SA<br />
G. WARDLE, QLD<br />
B. WARDLE, QLD<br />
S. WATTS, VIC<br />
T. WEDDING, QLD<br />
J. WEEN, SA<br />
S. WEEN, SA<br />
R. WHITE, VIC<br />
D. WHITTON, NSW<br />
S. WILKINSON, VIC<br />
E. WILSON, QLD<br />
G. WINFIELD, NSW<br />
L. WOLSTENHOLME, SA<br />
D. WOLSTENHOLME, SA<br />
P. WONG, NSW<br />
G. WORT, QLD<br />
H. XIAO, VIC<br />
R. YOUNG, TAS<br />
S. YOUNG, QLD<br />
S. YUNOS, SA<br />
M. ZEKO, NSW<br />
M. ZEMDEGS, SA<br />
Y. ZHENG, NSW<p />
    </template>

  </app-main>
</template>
<script>

export default {
    name:'AppWinners',
  
}
</script>

<style>
.table th,
.table td {
  padding: 0.5rem;
  vertical-align: top;
  border-top: 0;
  font-size: 0.8rem;
}
</style>
